const navbar = document.querySelector('.navbar');
const navbarBurgerBtn = document.querySelector('.navbar__burger-btn');
const overlay = document.querySelector('.overlay');
const sidebar = document.querySelector('.sidebar');
const sidebarCloseBtn = document.querySelector('.sidebar__close-btn');

// *********** Sidebar ***********
const toggleSidebar = () => {
    overlay.classList.toggle('overlay_hide');
    sidebar.classList.toggle('sidebar_hide');
}

// *********** Navbar ***********
const toggleNavbarBg = () => {
    if (window.pageYOffset > 10) {
        navbar.classList.add("navbar_bg_white");
    } else {
        navbar.classList.remove("navbar_bg_white");
    }
}

navbarBurgerBtn.addEventListener('click', toggleSidebar);
sidebarCloseBtn.addEventListener('click', toggleSidebar);

window.addEventListener('scroll', toggleNavbarBg);
